import React, { useState } from "react";
import { Collapse } from "react-collapse";
import ToggleChevron from "../../../Icons/ToggleChevron";

interface CollapsiblePanelHeaderProps {
  title: string | React.ReactElement;
  controlledOpen?: boolean;
  controlledOpenFunc?: any;
  classMod?: string;
  listQuantity: string;
}

const CollapsibleHeader: React.FC<CollapsiblePanelHeaderProps> = ({
  title,
  children,
  controlledOpen,
  controlledOpenFunc,
  classMod,
  listQuantity,
}) => {
  const [isOpened, setIsOpened] = useState(!!controlledOpen || false);
  const listQuantityNum = parseInt(listQuantity);
  return (
    <div
      className={`gm-collapsible-header ${classMod}`}
      onClick={
        listQuantityNum > 0
          ? () => {
              if (!!controlledOpenFunc) {
                controlledOpenFunc();
              }
              setIsOpened(!isOpened);
            }
          : undefined
      }
    >
      <section className="gm-panel-list-elem gm-collapsible-header-title">
        <h1 style={{ width: "90%" }}>
          {title}
          {listQuantity && (
            <div style={{ float: "right", color: "#36a2eb" }}>
              {listQuantity}
            </div>
          )}
        </h1>
        {parseInt(listQuantity) > 0 ? (
          <ToggleChevron isOpened={isOpened} classMod="gm-person-chevron" />
        ) : null}
      </section>
      {parseInt(listQuantity) > 0 && (
        <Collapse isOpened={isOpened}>{children}</Collapse>
      )}
    </div>
  );
};

export default CollapsibleHeader;
