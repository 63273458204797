import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { observer } from "mobx-react";
import { hitTest, sendMapClickEventToGA } from "./helpers";
import { useStore } from "../../../../Hooks";

import LayerDropdown from "./LayerDropdown";
import { PANEL_TYPES } from "../../../../Stores/PanelStore/types";
import LoadingSpinner from "../../../Icons/LoadingSpinner";

import { portalURLs, webmapId } from "../../../../constants/configTS";
import history from "../../../history";
import useEsriModules from "../../../../Hooks/useEsriModules";
import MapError from "../MapError";

const WebMapComponent = () => {
  const [opacity, setOpacity] = useState(1);
  const [error, setError] = useState(false);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const panelStore = useStore("panelStore");
  const dataStore = useStore("dataStore");

  const isPanelActive = panelStore.activePanel;

  const mobileView = window.screen.width <= 768;

  // Load Esri Classes using the hook.
  const { esriObj, layers, esriToken } = useEsriModules();

  let esriClasses = esriObj;

  useEffect(() => {
    if (esriObj != null && layers != null) {
      const timeout = setTimeout(() => {
        setError(true);
      }, 60000);
      ref.current = timeout;
    }
    initMap();
  }, [esriClasses, layers]);

  useEffect(() => {
    let isMap = history.location.pathname.slice(0, 5) === "/map/";
    const hashId = history.location.pathname.slice(5);
    let data = dataStore.getPerson(hashId);
    if (isMap && !!data && panelStore.mapLoaded) {
      panelStore.renderPerson(data, undefined);
    }
  }, []);

  const initMap = () => {
    if (esriObj != null && layers !== undefined) {
      const {
        WebMap,
        MapView,
        Basemap,
        Graphic,
        watchUtils,
        Layer,
        FieldsContent,
      } = esriObj;
      //this 3 also helps with the zooming in

      panelStore.setGraphic(Graphic);
      panelStore.setLayer(Layer);
      panelStore.setLayers(layers);

      // Generate the WebMap
      const theWebMap = new WebMap({
        portalItem: {
          id: webmapId,
        },
      });

      // Set the gray Basemap.
      const theBasemap = Basemap.fromId("dark-gray-vector");
      theWebMap.basemap = theBasemap;

      // Generate the View.
      const theView = new MapView({
        map: theWebMap,
        popup: {
          dockOptions: {
            buttonEnabled: false,
            collapseEnabled: false,
          },
        },
        constraints: {
          rotationEnabled: false,
        },
        container: "viewDiv",
      });

      panelStore.setMap(theWebMap, theView);

      //this one zooms in if there is a legislator selected

      theView.when((theView: any) => {
        watchUtils.whenNot(theView, "updating", function () {
          panelStore.setMapLoaded(true);
        });

        watchUtils.watch(theView, "animation", function (animation: any) {
          if (animation && animation.state === "running") {
          } else {
            setOpacity(0);

            if (ref.current != null) {
              clearTimeout(ref.current);
            }
          }
        });

        // // Check if all layers loaded
        const promArr: Promise<any>[] = [];

        theWebMap.allLayers.forEach((layer: any) => {
          if (layer?.loaded !== undefined) {
            promArr.push(
              new Promise((resolve, reject) => {
                watchUtils.whenTrueOnce(layer, "loaded", () => {
                  layer.outFields = ["*"];

         
                  const layerURL = layer.url.toLowerCase();

                  const stateURL = portalURLs.SENATE_CORE.toLowerCase();
                  const houseURL = portalURLs.HOUSE_CORE.toLowerCase();
                  const sldlURL = portalURLs.SLDL_CORE.toLowerCase();
                  const slduURL = portalURLs.SLDU_CORE.toLowerCase();

                  if (layerURL == stateURL) {
                    // If we want custom logic for state
                    layer.listMode = "hide";
                  } else if (layerURL == houseURL) {
                    layer.listMode = "hide";
                    const contentItem = layer.popupTemplate.content[0];

                    const title = layer.popupTemplate.title;
                    const newTitle = `House Member`;
                    layer.popupTemplate.title = newTitle;

                    let fieldsElement = new FieldsContent({
                      fieldInfos: layer.popupTemplate.fieldInfos,
                    });

                    /* Adding the contentItem to the popupTemplate.content array. */
                    layer.popupTemplate.content = [
                      {
                        type: "text",
                        text: "<span> State: {Abbrev} <span> </br> <span> District: {CDPP} </span> </br></br> <span><a href='{Explore}' rel='nofollow ugc'>Explore area</a></span>",
                      },
                    ];
                  } else if (layerURL == sldlURL) {
                    layer.listMode = "hide";
                    const contentItem = layer.popupTemplate.content[0];

                    const newTitle = `SLDL Member`;
                    layer.popupTemplate.title = newTitle;

                    /* Adding the contentItem to the popupTemplate.content array. */
                    layer.popupTemplate.content = [
                      {
                        type: "text",
                        text: "<span> Member Id: {SLDL_ID} <span> </br></br> <span><a href='{Explore}' rel='nofollow ugc'>Explore area</a></span>",
                      },
                    ];
                  } else if (layerURL == slduURL) {
                    layer.listMode = "hide";
                    const newTitle = `SLDU Member`;
                    layer.popupTemplate.title = newTitle;

                    /* Adding the contentItem to the popupTemplate.content array. */
                    layer.popupTemplate.content = [
                      {
                        type: "text",
                        text: "<span> Member Id: {SLDU_ID} <span> </br> </br> <span> <a href='{Explore}' rel='nofollow ugc'>Explore area</a></span>",
                      },
                    ];
                  }
                  resolve("");
                });
              })
            );
          }
        });
        Promise.all(promArr).then(() => {
          watchUtils.whenTrue(theView, "ready", function () {
            setOpacity(0);
            if (ref.current != null) {
              // clearTimeout(ref.current);
            }
          });
        });

        // Map Click Event
        theView.on("click", (event: any) => {
          hitTest(event, theView).then(
            (hitResult: { identityData: any; count: number }) => {
              const hashId = hitResult?.identityData?.hashID;

              let theLeaf: any = dataStore.getPerson_no_external(
                hashId
              ) as unknown;

              // TODO: Make this logic more robust
              const person = theLeaf?.members?.[0];

              // Special case for clicking on a state while viewing a senator.
              let skip = false;

              if (panelStore.activeHashId) {
                const truncHash = panelStore.activeHashId.slice(0, 6);
                if (
                  truncHash === "SENATE" &&
                  person?.identityInfo?.type === "STATE_SUM"
                )
                  skip = true;
              }

              if (
                person !== undefined &&
                hashId !== panelStore.activeHashId &&
                skip == false
              ) {
                panelStore.renderPerson(
                  person,
                  hitResult?.identityData.geometry
                );
                panelStore.setActiveHashId(hashId);
              }
            }
          );
        });

        //Watch the popups
        theView?.popup?.watch("selectedFeature", (feature: any) => {
          sendMapClickEventToGA(feature);
        });

        const urls = Object.values(portalURLs);
        const hideLayers = theWebMap.layers
          .flatten(function (item: any) {
            return item.layers || item.sublayers;
          })
          .find(function (layer: any) {
            if (urls.includes(layer.url)) {
              layer.listMode = "hide";
            }
          });

        hideLayers;
      });

      const { Legend, BasemapGallery, Expand, LayerList, Search, Home } =
        esriObj;

      const legendWidget = new Legend({
        view: theView,
      });

      const basemapGallery = new BasemapGallery({
        view: theView,
      });

      const expandBaseMap = new Expand({
        expandIconClass: "icon-ui-basemap",
        view: theView,
        content: basemapGallery,
      });

      const layerList = new LayerList({
        view: theView,
      });

      const expandLayerList = new Expand({
        expandTooltip: "Layer List",
        expandIconClass: "icon-ui-layer-list",
        view: theView,
        content: layerList,
      });

      var expandLegend = new Expand({
        expandTooltip: "Legend",
        expandIconClass: "icon-ui-menu",
        view: theView,
        content: legendWidget,
        expanded: false,
      });

      const searchWidget = new Search({
        view: theView,
      });
      const searchWidgetExpand = new Expand({
        expandTooltip: "Search",
        expandIconClass: "esri-icon-search", // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
        view: theView,
        content: searchWidget,
      });

      // // typical usage
      const homeButton = new Home({
        view: theView,
      });

      //mapStore.home = homeButton;

      // theView.whenLayerView(featureLayer).then(function (layerView) {
      //   layerView.watch("updating", function (value) {
      //     if (!value) {
      //       featureLayer.queryFeatureCount().then(function (results) {

      //       });
      //       layerView
      //         .queryFeatureCount()
      //         .then(function (results) {

      //         })
      //         .catch(function (error) {
      //         });
      //     }
      //   });
      // });

      // watchUtils?.when(theView, "stationary", () => {
      //   const names: any[] = [];

      //   theView?.layerViews?._items?.forEach((item: any) => {
      //     const some = item?.layer?.sourceJSON?.name;
      //     names?.push(some);
      //   });

      //   );
      // });

      const boundarySelectNode = document.createElement("div");
      boundarySelectNode.id = "boundarySelectNode";
      theView.ui.add(boundarySelectNode, "top-right");

      ReactDOM.createRoot(boundarySelectNode!).render(<LayerDropdown />);

      theView.ui.add(expandBaseMap, "top-left");
      theView.ui.add(expandLayerList, "top-left");
      theView.ui.add(searchWidgetExpand, "top-left");
      theView.ui.add(homeButton, "top-left");
      theView.ui.add(expandLegend, "bottom-right");

      // TODO: Reenable
      watchUtils.when(searchWidgetExpand, "expanded", function () {
        panelStore.registerPrevView();
        panelStore.setCrumb(["Search"], [null]);
        panelStore.setActiveView(PANEL_TYPES.SEARCH);
        if (panelStore.activePanel !== true) panelStore.setActivePanel();

        watchUtils.whenFalse(searchWidgetExpand, "expanded", function () {
          panelStore.setPrevView();
        });
      });

      watchUtils.when(expandLegend, "expanded", function (event: any) {

        panelStore?.setLegendExpanded(expandLegend?.expanded);

        watchUtils.whenFalse(expandLegend, "expanded", function () {
          panelStore.setLegendExpanded(false);
        });
      });
    }
  };

  return (
    <div className="gm-map-section">
      <div
        className={`make-this-transparent${
          opacity === 0 ? "make-display-none" : ""
        }`}
        style={{ backgroundColor: `rgba(29, 34, 35, ${opacity})` }}
      >
        {mobileView && (
          <>
            {!isPanelActive && (
              <>
                {error ? (
                  <MapError />
                ) : (
                  <div className="loading-spinner-container">
                    <LoadingSpinner />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {!mobileView && (
          <>
            {" "}
            {error ? (
              <MapError />
            ) : (
              <div className="loading-spinner-container">
                <LoadingSpinner />
              </div>
            )}
          </>
        )}

        {/* {error ? (
          <MapError />
        ) : (
          <div className="loading-spinner-container" style={{ zIndex: -1 }}>
            <LoadingSpinner />
          </div>
        )} */}
      </div>

      <div id="viewDiv" />
      {/* <LayerDropdown /> */}
    </div>
  );
};

export default observer(WebMapComponent);
