import { LegislatorTypes, IdentityInfo } from "APTAPackage";
import * as Clicks from "./Clicks";

//TODO: Extend DS to include state and move this back
export const genIdentState = (data: Clicks.IStateClick, geometry: any): IdentityInfo => {
  return new IdentityInfo(
    LegislatorTypes.STATE_SUM,
    data.Abbrev,
    "",
    "",
    "",
    geometry,
    ""
  );
};

export const genIdentHouse = (data: Clicks.IHouseClick, geometry: any): IdentityInfo => {
  if(data.CDPP === '1') data.CDPP = '00'
  return new IdentityInfo(
    LegislatorTypes.HOUSE,
    data.Abbrev,
    data.CDPP,
    "",
    data.CD_ID,
    geometry,
    ""
  );
};

function parseChamberString(idString: string) {
  // Find end of chamber string
  let stringEnd = 0;
  let dashCount = 0;
  for (let i = 0; i < idString.length; i++) {
    if (idString[i] === "-") {
      dashCount++;
      if (dashCount === 2) {
        stringEnd = i;
        i = 10000;
      }
    }
  }

  let chamber = idString.slice(3, stringEnd);
  let type = chamber === "upper" ? LegislatorTypes.SLDU : LegislatorTypes.SLDL;
  let Abbrev = idString.slice(0, 2).toUpperCase();
  let district = idString.slice(stringEnd + 1, idString.length);
  return { type, Abbrev, chamber, district };
}

export const genIdentSLDU = (data: Clicks.ISLDUClick, geometry: any): IdentityInfo => {
  const parsed = parseChamberString(data.SLDU_ID);
  return new IdentityInfo(
    LegislatorTypes.SLDU,
    parsed.Abbrev,
    parsed.district,
    parsed.chamber,
    "",
    geometry,
    ""
  );
};

export const genIdentSLDL = (data: Clicks.ISLDLClick, geometry: any): IdentityInfo => {
  const parsed = parseChamberString(data.SLDL_ID);
  return new IdentityInfo(
    LegislatorTypes.SLDL,
    parsed.Abbrev,
    parsed.district,
    parsed.chamber,
    "",
    geometry,
    ""
  );
};
