import React from "react";
import { useStore } from "../../../../../../Hooks";
import CommitteeElem from "../../ListElems/Committee";
import CollapsibleHeader from "../../CollapsibleHeader";
import { observer } from "mobx-react";
const CommList = () => {
  const searchStore = useStore("searchStore");
  const panelStore = useStore("panelStore");

  let houseComms: any[] = [];
  let senComms: any[] = [];
  let renderData: any = [];

  if (searchStore.filteredHouseComms.length !== 0) {
    searchStore.filteredHouseComms.forEach((theComm) => {
      houseComms.push(
        <CommitteeElem
          theComm={theComm}
          onClick={() => panelStore.renderCommitteePanel(theComm)}
        />
      );
    });
  }

  if (searchStore.filteredSenComms.length !== 0) {
    searchStore.filteredSenComms.forEach((theComm) => {
      senComms.push(
        <CommitteeElem
          theComm={theComm}
          onClick={() => panelStore.renderCommitteePanel(theComm)}
        />
      );
    });
  }
  renderData.push(
    <CollapsibleHeader
      title="Senate Committees"
      controlledOpen={searchStore.senCommsOpen}
      controlledOpenFunc={() =>
        searchStore.updateSenCommsOpen(!searchStore.senCommsOpen)
      }
      listQuantity={senComms.length.toString()}
    >
      {senComms}
    </CollapsibleHeader>
  );

  renderData.push(
    <CollapsibleHeader
      title="House Committees"
      controlledOpen={searchStore.houseCommsOpen}
      controlledOpenFunc={() =>
        searchStore.updateHouseCommsOpen(!searchStore.houseCommsOpen)
      }
      listQuantity={houseComms.length.toString()}
    >
      {houseComms}
    </CollapsibleHeader>
  );

  if (!renderData?.length) renderData = <p> No Results Found</p>;
  return <section>{renderData}</section>;
};

export default observer(CommList);
