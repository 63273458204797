import {
  API_URLS,
  DEV_BASE_URL,
  PROD_BASE_URL,
} from "../../constants/configTS";
import { observable, action, computed, makeObservable } from "mobx";
import { User } from "../../Types/User";
import { IAuthResponse } from "./types";
import { signInFootPrint, signInOnApta } from "./helpers";
import { AUTH_TYPES } from "../types";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { ArcGISIdentityManager } from "@esri/arcgis-rest-request";
import { sendGAEvent } from "../../Hooks/useGoogleAnalytics";

const CLOSED_WINDOW = "CLOSED_WINDOW";
const OTHER_WINDOW = "OTHER_WINDOW";

const windowConfig =
  "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

class AuthStore {
  // Local Storage for Token
  userStorage: Storage = window.localStorage;
  windowRef: Window | null = null;
  funcRef: any = null;
  // Auth Vars;
  @observable userInfo: any = {};
  @observable loadingAuth: boolean = true;
  @observable authStatus: AUTH_TYPES = AUTH_TYPES.INIT_AUTH;
  @observable user: User | undefined = undefined;

  @observable unauthUserID: string = "";

  @observable sessionProm: Promise<ArcGISIdentityManager> | null = null;
  @observable esriToken: any | null = null;
  @observable esriTokenIsLoading: boolean = false;
  @observable esriTokenIsError: boolean = false;

  // Check if User is Authenticated.
  @computed get isAuth() {
    return this.authStatus === AUTH_TYPES.SUCCESS_AUTH;
  }

  @computed get userId() {
    return this?.userInfo?.Individual?.ind_cst_key;
  }

  @action setEsriToken(newEsriToken: string) {
    this.esriToken = newEsriToken;
  }

  @action setEsriTokenStatus(args: {
    isLoading: boolean;
    isError: boolean;
    esriToken: string | null;
  }) {
    this.esriTokenIsLoading = args.isLoading;
    this.esriTokenIsError = args.isError;
    this.esriToken = args.esriToken;
  }

  processTokenProm() {
    return new Promise<ArcGISIdentityManager>(async (resolve, reject) => {
      if (this.sessionProm != null) {
        try {
          const res = await this.sessionProm;

          return resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    });
  }

  GET_token() {
    return new Promise<ArcGISIdentityManager>(async (resolve, reject) => {
      axios
        .get(API_URLS.GET_esriToken)
        .then((response) => {
          const token = response.data;

          const session = new ArcGISIdentityManager({
            token: token.token,
            tokenExpires: new Date(token.expires),
            ssl: token.ssl,
          });

          return resolve(session);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action GET_esri(isReset: boolean = false) {
    return new Promise<ArcGISIdentityManager>(async (resolve, reject) => {
      try {
        const timestamp = new Date();

        // If Session Promise Exists
        if (this.sessionProm != null) {
          const session = await this.processTokenProm();
          if (session.tokenExpires > timestamp) return resolve(session);
        }
        this.sessionProm = this.GET_token();
        const session = await this.processTokenProm();
        resolve(session);
      } catch (error) {
        reject(error);
      }
    });
  }

  @action GET_authToken() {
    return new Promise((resolve, reject) => {
      this.setEsriTokenStatus({
        isLoading: true,
        isError: false,
        esriToken: null,
      });

      axios
        .get(API_URLS.GET_esriToken)
        .then((response) => {
          this.setEsriTokenStatus({
            isLoading: false,
            isError: false,
            esriToken: response.data,
          });
          resolve(response.data);
        })
        .catch((error) => {
          this.setEsriTokenStatus({
            isLoading: false,
            isError: true,
            esriToken: null,
          });
          reject(error);
        });
    });
  }

  constructor() {
    makeObservable(this);
    this.initAuth();
  }

  auth(): Promise<string> {
    // If window already open return.
    if (this.windowRef !== null)
      return new Promise((resolve) => resolve(OTHER_WINDOW));
    // Open Popup
    this.windowRef = window.open(
      "https://www.aptagateway.com/eweb/DynamicPage.aspx?webCode=login&URL_success=http://localhost:3000/settings/?userToken={Token}",
      "AUTH_POPUP",
      windowConfig
    );
    // If Popup failed to open.
    if (this.windowRef === null)
      return new Promise((resolve) => resolve(CLOSED_WINDOW));

    // Promise that waits for an auth code.
    let codePromise = new Promise<string>((resolve, reject) => {
      // Function for responding to message is saved on class so it can be properly deleted.
      this.funcRef = (event: any) => {
        // Confirm event originates from our app.
        if (event.origin !== DEV_BASE_URL && event.origin !== PROD_BASE_URL) {
          return;
        }
        // Get String from window message.
        const data = event.data;
        if (!data) reject();
        // let queryVarName = data.slice(0, 6);
        // if (queryVarName !== "?code=") reject();
        // let parsedAuthCode = data.slice(6);
        // this.windowRef = null;
        resolve("resolved");
      };
      window.addEventListener("message", this.funcRef, false);
    });

    // Promise that checks if window closed before auth
    let closedPromise = new Promise<string>(async (resolve, reject) => {
      let closed = this.windowRef?.closed;
      while (!closed) {
        let checkProm = new Promise((resolve) => {
          setTimeout(() => {
            closed = this.windowRef?.closed;
            resolve(null);
          }, 1000);
        });
        await checkProm;
      }
      this.windowRef = null;
      resolve(CLOSED_WINDOW);
    });

    return Promise.race<Promise<string>>([codePromise, closedPromise]);
  }

  @action processToken = (authResponse: IAuthResponse) => {
    this.setUserStorage(authResponse);
  };

  @action setAuthStatus = (newValue: AUTH_TYPES) => {
    this.authStatus = newValue;
  };

  @action initAuth = async (setPrintModeOn?: any) => {
    this.setAuthStatus(AUTH_TYPES.LOADING_AUTH);
    this.readStoredAuth();
    if (setPrintModeOn) {
      setPrintModeOn();
    }
  };

  @action setLoadingAuth(newValue: boolean) {
    this.loadingAuth = newValue;
  }

  @action readStoredAuth = async () => {
    try {
      let storedAuth = this.getUserStorage();
      this.setLoadingAuth(true);
      if (!storedAuth) {
        this.setAuthStatus(AUTH_TYPES.SUCCESS_AUTH_NO_ACCOUNT);
        this.unauthUserID = uuidv4();
      }
      await this.signInApta();
    } catch (error) {
      this.setAuthStatus(AUTH_TYPES.SUCCESS_AUTH_NO_ACCOUNT);
      this.unauthUserID = uuidv4();
    } finally {
      this.setLoadingAuth(false);
    }
  };

  @action signIn = async (credentials: any) => {
    try {
      const authRes = await signInFootPrint(credentials);
      if (authRes?.data?.WebUserType) {
        this.userInfo = authRes?.data?.WebUserType;
        this.setUserStorage(authRes.data.WebUserType.Individual.ind_token);
        this.setAuthStatus(AUTH_TYPES.SUCCESS_AUTH);

        
        sendGAEvent({
          eventName: "login",
          others: {
            "user_id": authRes.data.WebUserType.Individual.ind_token,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  @action signInApta = async (token?: any) => {
    const storedToken = this.getUserStorage();
    if (storedToken) {
      try {
        const authRes = await signInOnApta(token || storedToken);
        if (authRes?.data?.WebUserType && (token || storedToken)) {
          this.userInfo = authRes?.data?.WebUserType;

          sendGAEvent({
            eventName: "login",
            others: {
              "user_id": authRes.data.WebUserType.Individual.ind_token,
            },
          });

          this.setUserStorage(token || storedToken);
          this.setAuthStatus(AUTH_TYPES.SUCCESS_AUTH);
        }
        return;
      } catch (error) {
        throw error;
      }
    }
  };

  @action signOut = () => {
    this.clearUserStorage();
    this.setAuthStatus(AUTH_TYPES.SUCCESS_AUTH_NO_ACCOUNT);
    this.unauthUserID = uuidv4();
  };

  // !!!!!!!!!!! Local Storage Helper Functions !!!!!!!!!!!!!!!!!!!!!!!!

  clearUserStorage = () => {
    localStorage.removeItem("auth");
  };

  setUserStorage = (authResponse: IAuthResponse) => {
    const stringAuthResponse = JSON.stringify(authResponse);
    this.userStorage.setItem("auth", stringAuthResponse);
  };

  getUserStorage = () => {
    const unparsedAuth = this.userStorage.getItem("auth");
    if (!unparsedAuth) return undefined;
    let auth = JSON.parse(unparsedAuth);
    return auth;
  };
}

export default AuthStore;
