import React from "react";
import { useStore } from "../../../../../../Hooks";
import { LegislatorTypes } from "APTAPackage";
import StateListElem from "../../ListElems/State";
import CollapsibleHeader from "../../CollapsibleHeader";
import PersonList from "../../thePersonList";
import { AddAllHouseState, AddAllSenState } from "../../ListElems/AddButtons";

const LegList = () => {
  const searchStore = useStore("searchStore");
  const dataStore = useStore("dataStore");
  const panelStore = useStore("panelStore");
  const hashObj: any = {};

  let SLDU: any[] = [];
  let SENATE: any[] = [];
  let SLDL: any[] = [];
  let HOUSE: any[] = [];
  let STATE_SUM: any;
  let renderData: any;

  searchStore.searchResults.map((elem: any) => {
    const hashID = elem?.[1];
    const person = dataStore.getPerson(hashID);
    if (person === undefined || hashObj?.[hashID] === true) return;
    hashObj[hashID] = true;
    switch (person?.identityInfo?.type) {
      case LegislatorTypes.HOUSE: {
        HOUSE.push(person);
        break;
      }
      case LegislatorTypes.SENATE: {
        SENATE.push(person);
        break;
      }
      case LegislatorTypes.SLDL: {
        SLDL.push(person);
        break;
      }
      case LegislatorTypes.SLDU: {
        SLDU.push(person);
        break;
      }
      case LegislatorTypes.STATE_SUM: {
        STATE_SUM = (
          <section>
            <StateListElem
              key={hashID}
              stateAbbrev={person.identityInfo.stateAbbrev}
            />{" "}
          </section>
        );
        break;
      }
    }
  });
  renderData = (
    <>
      {SENATE ? (
        <CollapsibleHeader
          title="Federal Senators"
          listQuantity={SENATE.length.toString()}
          controlledOpen={panelStore.senateOpen}
          controlledOpenFunc={() => {
            panelStore.setSectionOpen(LegislatorTypes.SENATE);
          }}
        >
          <PersonList
            key={"Senate"}
            people={SENATE}
            elem={<AddAllSenState legs={SENATE} />}
          />
        </CollapsibleHeader>
      ) : null}
      {HOUSE ? (
        <CollapsibleHeader
          title="Congressional Districts"
          listQuantity={HOUSE.length.toString()}
          controlledOpen={panelStore.houseOpen}
          controlledOpenFunc={() =>
            panelStore.setSectionOpen(LegislatorTypes.HOUSE)
          }
        >
          <PersonList
            key={"CON DISTRICTS"}
            people={HOUSE}
            elem={<AddAllHouseState legs={HOUSE} />}
          />
        </CollapsibleHeader>
      ) : null}
      {SLDU ? (
        <CollapsibleHeader
          title="State Upper Chamber"
          listQuantity={SLDU.length.toString()}
          controlledOpen={panelStore.SLDUOpen}
          controlledOpenFunc={() =>
            panelStore.setSectionOpen(LegislatorTypes.SLDU)
          }
        >
          <PersonList
            key={"UPPER-NC"}
            people={SLDU}
            elem={<AddAllHouseState legs={SLDU} />}
          />
        </CollapsibleHeader>
      ) : null}
      {SLDL ? (
        <CollapsibleHeader
          title="State Lower Chamber"
          listQuantity={SLDL.length.toString()}
          controlledOpen={panelStore.SLDLOpen}
          controlledOpenFunc={() =>
            panelStore.setSectionOpen(LegislatorTypes.SLDL)
          }
        >
          <PersonList
            key={"LOWER-NC"}
            people={SLDL}
            elem={<AddAllHouseState legs={SLDL} />}
          />
        </CollapsibleHeader>
      ) : null}
    </>
  );

  if (renderData.length === 0) {
    return <p> No Results Found</p>;
  }
  return renderData;
};

export default LegList;
