import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../Hooks";
import SearchElem from "./searchElem";
import "./TrieSearch.css";

//@ts-ignore
import OutsideSearch from "./outsideClick";
//import { TrieValue } from "../../../Types/Tries/TrieValue";
//import TrieSearchElem from "./TrieSearchElem";

//@ts-ignore
import { updateSearchState } from "../actions/actions";
// import "./TrieSearch.css";
import { BiX } from "react-icons/bi";

const TrieSearch = (props: any) => {
  const searchRef = useRef(null);
  const dataStore = useStore("dataStore");

  const [searchValue, setSearchValue] = useState("");
  const [searchRes, setSearchRes] = useState<any[]>([]);

  const onChange = (localValue: string) => {
    let parsedValue = localValue.toLowerCase();
    let localSearchRes = undefined;

    if (parsedValue !== "") {
      if (dataStore.loaded) {
        localSearchRes = dataStore?.Tries?.generalTrie.find(parsedValue);
      }
    }

    setSearchValue(localValue);
    if (localSearchRes !== undefined) setSearchRes(localSearchRes);
  };

  if (dataStore.loaded === false) {
    return (
      <div className="gm-search-parent">
        <p> Loading...</p>
      </div>
    );
  }

  let max = searchRes.length;
  let renderData: any[] = [];

  if (searchValue.length <= 3) {
    max = 200;
  }

  if (max > searchRes.length) max = searchRes.length;

  let checkObj: any = {};

  for (let i = 0; i < max; i++) {
    const hashID = searchRes[i][1];
    const thePerson = dataStore.getPerson(hashID);
    if (!checkObj[hashID] && thePerson !== undefined) {
      checkObj[hashID] = true;
      renderData.push(<SearchElem key={hashID} person={thePerson} />);
    }
  }

  return (
    <div className="gm-search-parent" id="search_section">
      <div className="container">
        <div className="gm-search-bar row">
          <div className="col">
            <div
              id="searchInputWrap"
              style={{
                padding: 10,
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <input
                id="app-search-input"
                type="text"
                placeholder="Search for State, District, or Legislator"
                className="gm-input input-search input-minimal"
                autoComplete="off"
                onChange={(e) => onChange(e.target.value)}
                value={searchValue}
              />
              {searchValue !== "" ? (
                <div
                  className="gm-search-clear"
                  onClick={() => {
                    onChange("");
                  }}
                >

                <BiX size={'2.75rem'} color = '#959595'/>
                  {/* {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="svg-icon"
                  >
                    <path d="M18.404 16l9.9 9.9-2.404 2.404-9.9-9.9-9.9 9.9L3.696 25.9l9.9-9.9-9.9-9.898L6.1 3.698l9.9 9.899 9.9-9.9 2.404 2.406-9.9 9.898z" />
                  </svg>{" "} */}
                </div>
              ) : null}
            </div>

            {searchValue !== "" && renderData.length ? (
              <div ref={searchRef} className="gm-dropdown-search">
                <ul>{renderData}</ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TrieSearch);
